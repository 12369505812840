import React from "react";

function LanguageSelection({ handleLanguageSelect }) {
  return (
    <div className="sagar">
      <h2 className="sagarh2">Select your preferred language:</h2>
      <div className="sagarmodal">
        <button onClick={() => handleLanguageSelect("en")}>English</button>
        <button onClick={() => handleLanguageSelect("ar")}>العربية</button>
      </div>
    </div>
  );
}

export default LanguageSelection;
