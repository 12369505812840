import React, { useEffect, useState,useRef } from "react";
import { useLocation } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingAnimation from "./assets/Animation.json";
import { ComponentCollection } from "survey-core";
import "./index.css";
import { surveyLocalization } from "survey-core";
import "survey-core/survey.i18n";
import LanguageSelection from "./components/LanguageSelection";
import { nps, csat } from "./components/npsandcsatquestions";


ComponentCollection.Instance.add(nps);
ComponentCollection.Instance.add(csat);


// Main App Component
function App() {
  //  all states
  const [surveyJson, setSurveyJson] = useState(null);
  const [themeJson, setThemeJson] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const selectedLanguageRef = useRef();

  useEffect(() => {
    selectedLanguageRef.current = selectedLanguage;
  }, [selectedLanguage]);



  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const uuid = queryParams.get("uuid");
  const channel = queryParams.get("channel");
  const domainId = queryParams.get("domain_id");
  const contactid = queryParams.get("contact_form");
  const surv = queryParams.get("survey_forms");
  const uuidd = queryParams.get("uuidd");
  const mrnumber = queryParams.get("mrnumber");
  const lang = queryParams.get("lang");


  if (lang && lang !== selectedLanguage) {
    setSelectedLanguage(lang);
    console.log(selectedLanguage);
  }

  useEffect(() => {
    const handleSurveyData = (data) => {
      data = JSON.parse(data);
      const pages = data.pages || [];

      for (const page of pages) {
        const elements = page.elements || [];
        for (const element of elements) {
          const title = element.title;
          if (typeof title === 'object' && title.default) {
            return;
          } else {
            setSelectedLanguage("en");
            break;
          }
        }
      }
    };

    if (surveyJson) {
      handleSurveyData(surveyJson);
    }
  }, [surveyJson]);

  const handleLanguageSelect = (language) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("lang", language);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.location.href = newUrl;
  };
  

  useEffect(() => {
    if (uuid) {
      async function fetchSurveyData() {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}.get_survey_data?uuid=${uuid}&channel=${channel}&domain_id=${domainId}`, {
            method: "POST",
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          if (data.message.status === "Already") {
            console.log("Already submitted");
            setAlreadySubmitted(true);
            return;
          }

          if (data.message.survey_data) {
            setSurveyJson(data.message.survey_data);
            if (data.message.theme_json !== null) {
              setThemeJson(JSON.parse(data.message.theme_json));
            }
          } else {
            throw new Error("Error fetching survey data");
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      }

      fetchSurveyData();
    }
  }, [uuid, channel, domainId]);

  useEffect(() => {
    if (contactid) {
      const engLocale = surveyLocalization.locales["en"];

      engLocale.completeText = "Proceed";

      const customLocaleStrings = {
        completeText: "Proceed",
      };
      surveyLocalization.locales["my"] = customLocaleStrings;
      async function fetchSurveyData() {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}.get_contact_survey_data?contact_form=${contactid}`,
            {
              method: "POST",
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          if (data.message.status === "Already") {
            console.log("Already submitted");

            setAlreadySubmitted(true);
            return;
          }

          if (data.message.survey_data) {
            setSurveyJson(data.message.survey_data);
            if (data.message.theme_json !== null) {
              setThemeJson(JSON.parse(data.message.theme_json));
            }
          } else {
            throw new Error("Error fetching survey data");
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      }

      fetchSurveyData();
    }
  }, [contactid]);

  useEffect(() => {
    if (surv) {
      async function fetchSurveyData() {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}.get_contact_survey_datas?survey_forms=${surv}`,
            {
              method: "POST",
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          console.log(data);
          if (data.message.status === "Already") {
            setAlreadySubmitted(true);
            return;
          }

          if (data.message.survey_data) {
            setSurveyJson(data.message.survey_data);

            if (data.message.theme_json !== null) {
              setThemeJson(JSON.parse(data.message.theme_json));
            }
          } else {
            throw new Error("Error fetching survey data");
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      }

      fetchSurveyData();
    }
  }, [surv]);

  useEffect(() => {
    if (surveyJson) {
      const newSurvey = new Model(surveyJson);
      setSurvey(newSurvey);
    }
  }, [surveyJson]);

  useEffect(() => {
    if (survey && themeJson) {
      survey.applyTheme(themeJson);
    }
  }, [survey, themeJson]);

  useEffect(() => {
    if (survey && contactid) {
      survey.onComplete.add(alertResultss);
    }
  }, [survey, contactid]);
  

  useEffect(() => {
    if (survey && uuid) {
      survey.onComplete.add(alertResults);
    }
  }, [survey, uuid]);
  useEffect(() => {
    if (survey && surv) {
      survey.onComplete.add(alertResultsss);
    }
  }, [survey, surv]);

  var alertResults = (sender) => {
    const results = sender.data;
    const hell2 = JSON.stringify({ responses: results });
    console.log("Survey results:", results);
    const requestData = {
      uuid: uuid,
      channel: channel,
      responses: hell2,
      language: selectedLanguage,
    };

    fetch(`${process.env.REACT_APP_API_URL}.response_survey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("POST request response:", data);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  var alertResultss = (sender) => {
    survey.showCompletedPage = false;
    const results = sender.data;
    const hell2 = JSON.stringify({ responses: results });
    const requestData = {
      surv: surv,
      uuidd: uuidd,
      responses: hell2,
      language: selectedLanguage,
    };
    console.log("multi language",requestData);
  
    fetch(`${process.env.REACT_APP_API_URL}.contact_response_survey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message.number);
        const mr_number = data.message.number;
        const currentURL = window.location.href;
  
        const modifiedURL = currentURL
          .replace("contact_form", "contact_forms")
          .replace("survey_form", "survey_forms");
  
        var newURL = new URL(modifiedURL);
  
        setTimeout(function () {
          newURL.searchParams.set("mrnumber", mr_number);
          newURL.searchParams.set("lang", selectedLanguageRef.current);
          window.location.href = newURL;
        }, 1000);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  

  var alertResultsss = (sender) => {
    var ur_1 = window.location.href;
    var ur_2 = ur_1
      .replace("contact_forms", "contact_form")
      .replace("survey_forms", "survey_form")
      .replace(/(\?|&)lang=[^&]+/, "$1");

    setTimeout(function () {
      window.location.href = ur_2;
    }, 8000);
    const results = sender.data;
    const hell2 = JSON.stringify({ responses: results });
    console.log("Survey results:", results);
    const requestData = {
      surv: surv,
      uuidd: uuidd,
      responses: hell2,
      mrnumber: mrnumber,
      language: selectedLanguage,
    };

    fetch(`${process.env.REACT_APP_API_URL}.contact_2_response_survey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("POST contact form request response:", data);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  if (alreadySubmitted) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "30px",
        }}
      >
        <h5>Feedback already submitted</h5>
      </div>
    );
  }

  if (!survey) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Player
          loop
          autoplay
          src={loadingAnimation}
          style={{
            width: "350px",
            height: "auto",
          }}
        />
      </div>
    );
  }

  return (
    // <div>
    //   {!selectedLanguage && <LanguageSelection handleLanguageSelect={handleLanguageSelect} />}
    //   {selectedLanguage && (
    //     <div>
    //       <style>
    //         {selectedLanguage === "ar" ? ".sd-title.sd-element__title { text-align: end; }" : ""}
    //       </style>
    //       <Survey model={survey} locale={selectedLanguage} />
    //     </div>
    //   )}
    // </div>
    <div>
  {!selectedLanguage && <LanguageSelection handleLanguageSelect={handleLanguageSelect} />}
  {selectedLanguage && (
    <div>
      <Survey model={survey} locale={selectedLanguage} />
    </div>
  )}
</div>

  );
}

export default App;
